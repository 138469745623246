import React from "react"
import { graphql, Link } from "gatsby"

import { Layout } from "../components/common"
import { MetaData } from "../components/common/meta"

const Country = ({ data, location }) => {
    const distinctCountries = data.countries?.nodes?.filter((node, index, self) =>
        index === self.findIndex((t) => (
            t.path === node.path
        ))
    )

    return (
        <>
            <MetaData 
                location={location} 
                title="Race Tracks by Countries"
                description="Find motorsport and go-karting tracks around the world."
                />
            <Layout>
                <h1>Race Tracks by Countries</h1>
                <ul className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    <li>
                        <a href="/united-states">United States</a>
                    </li>
                    {distinctCountries
                        .filter((c) => c.country != "United States")
                        .map(({ country, path }) => (
                            <li>
                                <a href={path}>{country}</a>
                            </li>
                        ))}
                </ul>
            </Layout>
        </>
    )
}
export default Country

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
// countryPath: gatsbyPath(filePath: "/countries/{worldcitiesFilteredCsv.country}")
export const pageQuery = graphql`
    query CountriesQuery {
        countries: allWorldcitiesFilteredCsv( sort: { order: ASC, fields: [country] },) {
            nodes {
                path: gatsbyPath(
                    filePath: "/countries/{worldcitiesFilteredCsv.country}"
                )
                country
            }
        }
    }
`
